:root {
  --bgdark: #111;
  --bghover: #222;
  --yellow: #E4C732;
  --text: rgba(255, 255, 255, 0.80);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--bgdark);
  font-family: "Syne", sans-serif;
  color: #fff;
}

.container {
  width: 90%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;
}

header.menu .container {
  display: flex;
  justify-content: flex-end;
}

header.menu {
  padding-top: 25px;
  padding-bottom: 25px;
  z-index: 9;
  position: relative;
  background-color: var(--bgdark);
}

a.ham-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: clamp(1rem, 0.8438rem + 0.5vw, 1.125rem);
  padding: 13px 20px;
  background-color: var(--bgdark);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.apphome {
  padding-top: 20px;
  padding-bottom: 80px;
}

.apphome .container {
  display: flex;
  justify-content: space-between;
}

.apphome .container .leftcol {
  width: 55%;
}

.leftcol h1 {
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 51px;
  font-size: 30px;
  font-size: clamp(1.5625rem, 1.1719rem + 1.25vw, 1.875rem);
}

.leftcol p.hi {
  margin-bottom: 25px;
  font-size: 30px;
  font-size: clamp(1.5625rem, 1.1719rem + 1.25vw, 1.875rem);
}

.apphome .container .rightcol {
  width: 40%;
}

img {
  max-width: 100%;
}

.rightcol img {
  border-radius: 20px;
}

.leftcol h1 span {
  color: var(--yellow);
}

a.ham-btn:hover {
  background-color: var(--bghover);
}

h2 {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 24px;
}

.startup-logos img {
  max-width: 47px;
  border-radius: 5px;
}

.startup-logos {
  display: flex;
  align-items: center;
  gap: 16px;
}

.startups {
  margin-bottom: 51px;
}

.single-year {
  margin-bottom: 20px;
  position: relative;
  margin-left: 35px;
}

.single-year h3 {
  font-size: 20px;
  font-weight: normal;
  color: var(--yellow);
  margin-bottom: 6px;
}

.single-year p {
  font-size: 16px;
  color: var(--text);
}

.menu-dropdown {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7294117647);
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  -webkit-backdrop-filter: blur(21px);
          backdrop-filter: blur(21px);
  transition: all 0.5s ease;
  z-index: 2;
}

.menu-dropdown ul {
  transform: translateY(30px);
  transition: all 0.5s ease;
  opacity: 0;
  list-style: none;
}

.menu-dropdown.active ul {
  transform: translateY(0px);
  opacity: 1;
}

.menu-dropdown.active {
  visibility: visible;
  opacity: 1;
}

.menu-dropdown ul a {
  font-size: 30px;
  font-size: clamp(1.25rem, 0.4688rem + 2.5vw, 1.875rem);
  margin-bottom: 20px;
  display: block;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

body.scroll-lock {
  overflow: hidden;
}

.menu-dropdown ul a::before {
  content: "";
  width: 100%;
  bottom: 0;
  height: 0px;
  background: var(--yellow);
  position: absolute;
  z-index: -1;
  transition: all 0.1s ease;
}

.menu-dropdown ul a:hover::before {
  height: 15px;
}

.single-year::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 1px;
  background: var(--yellow);
  top: 12px;
  left: -35px;
}

@media (max-width: 600px) {
  .apphome .container {
    flex-direction: column;
  }
  .apphome .container .rightcol,
  .apphome .container .leftcol {
    width: 100%;
  }
  .timeline {
    margin-bottom: 51px;
  }
}/*# sourceMappingURL=App.css.map */